<template>
  <div class="search-box">
    <el-cascader :options="options" v-model="selectedOptions" placeholder="请选择地区" @change="handleChange" clearable size="small" style="width:150px" :show-all-levels="false" :props="{ checkStrictly: true }"></el-cascader>
    <el-input v-model="input" placeholder="请输入品牌" size="small" style="width:150px;margin:0 0 0 14px" suffix-icon="el-icon-search" @input="valueChange"></el-input>
  </div>
</template>
<script>
import { provinceAndCityData } from 'element-china-area-data'
import { debounce } from '@/utils/utils'

export default {
  props: {
    areaCode: {
      type: String,
      default: ''
    },
    findStr: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      input: '',
      selectedOptions: [],
      options: provinceAndCityData
    }
  },
  methods: {
    handleChange(value) {
      debounce(() => {
        this.update()
      }, 300)
    },
    valueChange(value) {
      debounce(() => {
        this.update()
      }, 300)
    },
    update() {
      let code = this.selectedOptions[this.selectedOptions.length - 1]
      if (code) {
        if (this.selectedOptions.length == 1) {
          code = code.slice(0, 2)
        } else {
          code = code.slice(0, 4)
        }
      } else {
        code = ''
      }
      this.$emit('update:areaCode', code)
      this.$emit('update:findStr', this.input)
    }
  }
}
</script>
<style lang="scss">
.search-box {
  display: flex;
  align-items: center;
  //   .el-cascader {
  .el-input {
    .el-input__inner {
      border: none;
      background: url("../../static/img/search/input_bg.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
  .el-cascader .el-input .el-icon-arrow-down {
    color: #c25835;
  }
  .el-input--small .el-input__icon {
    color: #c25835;
  }
  //   }
}
</style>